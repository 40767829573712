import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PageFb from './pages/PageFb';
import Home from './pages/Home';
import Pix from './pages/Pix';
import DeskHome from './pages/DeskHome';
import AnteciparHome from './pages/AnteciparHome';
import TaxaHome from './pages/TaxaHome';

import NotFound from './pages/NotFound';
import ReactGA from "react-ga4";
import { PriceProvider } from './store/price'


ReactGA.initialize("G-M1YFKE7FPK");

export default function Rotas() {

  React.useEffect(() => {
    const previousUrl = document.referrer;
    
    ReactGA.send({ hitType: "pageview", page: "/referrer", title: previousUrl });


    if (localStorage.getItem('google')) {
      window.location.href="about:blank";
    }
    
    if (document?.referrer?.includes('google')) {
      localStorage.setItem('google', true)
      ReactGA.send({ hitType: "pageview", page: "/home", title: "veio do google" });
      window.location.href="about:blank";
    } else {
      ReactGA.send({ hitType: "pageview", page: "/referrer", title: document?.referrer });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <PriceProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<PageFb />} />
          <Route exact path="/app" element={<Home />} />
          <Route exact path="/saque" element={<Pix />} />

          <Route exact path="/desbloquear-home" element={<DeskHome />} />
          <Route exact path="/antecipar-home" element={<AnteciparHome />} />
          <Route exact path="/taxa-home" element={<TaxaHome />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </PriceProvider>
  );
}
