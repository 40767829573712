import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal';

export const ModalBody = styled(Modal.Body)`
  border: 2px solid #fff;
  background: linear-gradient(180deg, rgb(33, 40, 63), rgb(14 25 64 / 80%));
  padding: 20px;
`

export const Price = styled.p`
  color: #fff;
  margin: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 20px;
`
