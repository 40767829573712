import React from 'react'
import Text from '../../components/base/Text'
import Modal from 'react-bootstrap/Modal';
import { usePriceConsumer } from '../../store/price';
import Lottie from "lottie-react";
import { useSpring, animated } from 'react-spring';
import groovyWalkAnimation from "./groovyWalk.json";

import * as Styled from './styles'

const ModalInfo = ({ onClose, show }) => {
  
  const { price } = usePriceConsumer()

  const { price: animatedNumber } = useSpring({
    from: { price:  50 },
    to: { price }, // Usando o valor do contexto aqui
    config: { duration: 1000 },
  });

  return (
    <>
      <Modal show={show} onHide={onClose} centered data-backdrop="false" backdrop={false}>
        <Styled.ModalBody>
            <div style={{ width: '125px', margin: '0 auto' }}>
              <Lottie animationData={groovyWalkAnimation} loop={true} width="125px" />
            </div>

            <Text
              text="Saldo atualizado!!"
              color="#fff"
              size="19px"
              margin="0 0 10px"
              align="center"
            />


            <Text
              text="Você recebeu:"
              color="#fff"
              size="19px"
              margin="0 0 0"
              align="center"
            />

            <Styled.Price>
              <span>R$ </span> <animated.div>{animatedNumber.interpolate((val) => Math.floor(val))}</animated.div>,00
            </Styled.Price>
        </Styled.ModalBody>
      </Modal>

    </>
  )
}

export default ModalInfo;