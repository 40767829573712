import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import AppContent from '../AppContent'
import { useSpring, animated } from 'react-spring';
import { usePriceConsumer } from '../../store/price';
import Button from '../../components/base/Button'
import PixPreview from '../PixPreview';
import { useNavigate } from 'react-router-dom';


import * as Styled from './styles'

const ProgressBar = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWidth(prevWidth => {
        if (prevWidth >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevWidth + 1; // Ajuste este valor para controlar a velocidade de incremento
      });
    }, 10); // Ajuste este valor para controlar a velocidade da animação

    return () => clearInterval(interval); // Limpeza do intervalo
  }, []);

  return (
    <Styled.Progressbar>
      <div style={{ width: `${width}%` }}></div>
    </Styled.Progressbar>
  );
};


const  ContentQuestions = ({ itemSelected }) => {
  const [show, setShow] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const { price } = usePriceConsumer()
  const [pixView, setPixView] = useState(false);
  let navigate = useNavigate();
  

  const isMobile = window.screen.width < 1000;
  
  // const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true)

  const { price: animatedNumber } = useSpring({
    from: { price:  50 },
    to: { price }, // Usando o valor do contexto aqui
    config: { duration: 1000 },
  });


  const handleCheckPix = () => {
    // handleClose()
    // setPixView(true)   
    
    navigate('/saque')
  }

  const handlePreviewStep2 = () => {
    setPixView(false)
    setNextStep(true)
  }

  return (
    <>
      {pixView ? (
        <>
          <PixPreview onPreviewStep={handlePreviewStep2} />
        </>
      ) : (
        <>
          <Modal
            open={show}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Styled.Content>
              <ProgressBar />

              <Styled.BoxPrice>            
                <p>
                  <span>R$ </span> <animated.div>{animatedNumber.interpolate((val) => Math.floor(val))}</animated.div>,00
                </p>
              </Styled.BoxPrice>

                <h1>PARABÉNS!</h1>
                <p>Você acaba de ganhar <span>R$ {price},00</span></p>
                <p>Receba o seu primeiro saque</p>

                <Button
                    align="center"
                    margin="30px 0 0"
                    text="Sacar Agora"
                    size={isMobile ? '13px' : '15px'}
                    width={isMobile ? '80%' : 'auto'}
                    // evtCategory="btn presell"
                    background="#55c455"
                    backgroundHover="#489348"
                    // animationNone
                    onClick={handleCheckPix}
                />
                
            </Styled.Content>
          </Modal>

          <AppContent onSubmit={handleOpen} isNextStep={nextStep} />
        </>
      )}
    </>
  )
}

export default ContentQuestions;