import React from 'react';
import Header from '../../components/Header'
import PresellHeadLine from '../../components/PresellHeadLine'
import ModalInitial from '../../components/Modal'
import ReactGA from "react-ga4";
// import MenuFake from '../../imgs/menu.png'

import * as Styled from './styles'

const isMobile = window.screen.width < 1000;

const PageHome = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    
    if (!isMobile) {
      ReactGA.send({ hitType: "pageview", page: "/home", title: "deslogando desktop" });
      window.location.href="about:blank";
    }
  }, [])
  

  return (
    <Styled.BoxInfo>
      <Styled.BoxInfossss>
        <Header
          padding="15px 0"
          isMobile={isMobile}
        />
        <PresellHeadLine />
        <ModalInitial />
      </Styled.BoxInfossss>

      {/* <Styled.Menu>
        <img src={MenuFake} alt="Menu" />  
      </Styled.Menu> */}
    </Styled.BoxInfo>
  );
}

export default PageHome;
