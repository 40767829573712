import PropTypes from 'prop-types';
import React from 'react';
import { Text, Content } from './styles';
import { BsFillCheckCircleFill } from "react-icons/bs";

const TextItem = ({ text, color, subColor, size, margin, align, fontWeight, hasIconCheck = false, iconColor, children, uppercase }) => {

  if (children) {
    return (
      <Text
        color={color}
        subColor={subColor}
        size={size}
        margin={margin}
        align={align}
        fontWeight={fontWeight}
      >
        {children}
      </Text>
    )
  }

  return (
    <>
      {hasIconCheck ? (
        <Content>
          <BsFillCheckCircleFill style={{ color: iconColor, fontSize: size }} />
          <Text
            color={color}
            subColor={subColor}
            size={size}
            dangerouslySetInnerHTML={{ __html: text }}
            margin={margin}
            align={align}
            fontWeight={fontWeight}
          />
        </Content>
      ) : (
        <Text
          color={color}
          subColor={subColor}
          size={size}
          dangerouslySetInnerHTML={{ __html: text }}
          margin={margin}
          align={align}
          fontWeight={fontWeight}
          uppercase={uppercase}
        />
      )}
    </>
  )
}

TextItem.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  size: PropTypes.string,
  subColor: PropTypes.string,
  align: PropTypes.string,
  fontWeight: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasIconCheck: PropTypes.bool,
};

TextItem.defaultProps = {
  color: '#fff',
  iconColor: '#fff',
  align: 'left',
  subColor: 'rgb(20 157 157)',
  size: '14px',
  margin: 0,
  fontWeight: 'normal',
  hasIconCheck: false
};

export default TextItem;
