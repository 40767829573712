import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
    padding-bottom: 100px;
`


export const Box = styled.div`
  position: relative;
  padding: 20px;
  background-color: #21283f;
  flex-direction: column;
  border-radius: 6px;
  gap: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 1;

  > b {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }

  .box-saldo {
    background-color: rgb(225 223 223);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px #4e4e4ecc;
    font-weight: bold;
    color: rgb(88, 188, 124);
    font-size: 19px;
  }

  input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    color: #0F1835;
    outline: none;
    border: 1px solid #0F1835;
    background-color: white;
  }

  .newView {
    b {
      color: #09BD4D;
      display: block;
      text-align: center;
      margin-bottom: 18px;
    }

    p {
      color: #fff;
      font-size: 15px;
      text-align: center;
      display: block;
    }
  }
`

export const BoxPix = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
`
export const BoxContentPix = styled.div`
  border-radius: 8px;
  height: 90px;
  align-items: center;
  gap: 5px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #0F1835;
  font-size: 12px;
  
  color: #fff;
  background-color: ${props => props.active ? '#0F1835' : 'unset'};  
`