import styled from 'styled-components'

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  padding: 32px 16px;
  background-color: #192137;
  border-radius: 8px;
  
  > h1, p, span {
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin: 0;
  }

  h1 {
    color: #53c776;
    margin-bottom: 17px;
  }

  span {
    color: #53c776;
    display: block;
    margin: 17px 0;
  }

  > div {
    margin-top: 18px;
  }
`