import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle` 
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    line-height: 1.2;
    font-family: 'Open Sans', sans-serif;
  }

  html, body, #root {
    min-height: 100%;
    margin: 0;
  }

  body {
    background: #0F1835;
    margin: 0;
    -webkit-font-smoothing: antialiased !important;
    margin: 0 0 80px;

    @media (min-width: 1010px) {
      margin: 0;
    }
  }

  .container {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 1;
    position: relative;

    @media (min-width: 1010px) {
      padding: 0;
      width: 1075px;
    }
  }

  .swiper {
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .accordion-item {
    background-color: transparent;
    box-shadow: none;
    border-color: rgb(58 58 58);
  }

  .accordion-button:not(.collapsed) {
    color: rgb(70, 255, 241) !important;
  }

  .accordion-item .accordion-button {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgb(58 58 58);
    color: #fff;
    font-weight: bold;
    font-size: 14px;

    @media (min-width: 1010px) {
      font-size: 17px;
    }
  }

  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    background-color: #A7ECDA26;
    border-color: #A7ECDA;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes grow {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  .checkmark {
    position: relative;
    padding: 30px;
    display: block;
    margin: 0 auto;
    /* animation: checkmark 5.6s cubic-bezier(0.42, 0, 0.275, 1.155) both; */
  }

  .confetti {
    position: absolute;
    animation: grow 1.4s cubic-bezier(0.42, 0, 0.275, 1.155) both alternate-reverse infinite;
  }
  .confetti:nth-child(1) {
    width: 12px;
    height: 12px;
    left: 12px;
    top: 16px;
  }
  .confetti:nth-child(1) {
  animation-delay: 0.7s;
  }
  .confetti:nth-child(2) {
      width: 18px;
      height: 18px;
      left: 168px;
      top: 84px;
  }
  .confetti:nth-child(2) {
    animation-delay: 1.4s;
}
.confetti:nth-child(3) {
    animation-delay: 2.1s;
}

.confetti:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 32px;
    top: 162px;
}
.confetti:nth-child(4) {
    animation-delay: 2.8s;
}

.confetti:nth-child(4) {
    width: 20px;
    height: 20px;
    left: 82px;
    top: -12px;
}
.confetti:nth-child(5) {
    animation-delay: 3.5s;
}

.confetti:nth-child(5) {
    width: 14px;
    height: 14px;
    left: 125px;
    top: 162px;
}
.confetti:nth-child(6) {
    animation-delay: 4.2s;
}

.confetti:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 16px;
    top: 16px;
}
.checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: #fff;
}
.checkmark__back {
    animation: rotate 35s linear both infinite;
}
`;
