import styled from 'styled-components';

export const Button = styled.a`
  @keyframes pulse2 {
    0% {
      box-shadow: 0 0 0 0px rgb(29, 101, 101);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(255, 100, 100, 0);
    }
  }
  @keyframes pulse3 {
    0% {
      box-shadow: 0 0 0 0px rgb(255, 100, 0);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(7, 255, 106, 0);
    }
  }

  border-radius: 9px;
  border: 0;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  font-size: ${props => props.size};
  color: ${props => props.color};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background-color: ${props => props.background};
  width: ${props => props.width};
  transition: all 0.5s ease-in-out;
  animation: ${props => props.animationNone ? 'none' : 'pulse2 1s infinite'} ;
  z-index: 3;
  text-decoration: none;
  text-align: ${props => props.align};

  /* &:hover,
  &:focus {
    transition: all 0.3s ease-in-out;
    animation: pulse3 1s infinite;
    background-image: linear-gradient(rgb(255, 200, 0) 0%, rgb(255, 100, 0) 100%);
  } */

  &:disabled {
    background-color: #c1c1c1 !important;
    animation: none;
    cursor: auto;
  }

  &.isHelp {
    background-color: rgb(255, 226, 53);
  }
`;

export const BoxButton = styled.div`
  display: ${props => (props.showBtn ? 'flex' : 'none')};
  justify-content: ${props => props.align};
  width: 100%;

  @media (min-width: 1010px) {
    display: flex;
  }
`;



export const ButtonHelp = styled.button`
  @keyframes pulse2 {
    0% {
      box-shadow: 0 0 0 0px rgb(29, 101, 101);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(255, 100, 100, 0);
    }
  }
  @keyframes pulse3 {
    0% {
      box-shadow: 0 0 0 0px rgb(255, 100, 0);
    }
    100% {
      box-shadow: 0 0 0 15px rgba(7, 255, 106, 0);
    }
  }

  border-radius: 9px;
  border: 0;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  font-size: ${props => props.size};
  color: ${props => props.color};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width};
  transition: all 0.5s ease-in-out;
  animation: pulse2 1s infinite;
  z-index: 3;
  background-color: rgb(255, 226, 53);
  color: #000;

  &:hover,
  &:focus {
    /* background-color: ${props => props.backgroundHover}; */
    transition: all 0.3s ease-in-out;
    animation: pulse3 1s infinite;
    background-image: linear-gradient(rgb(255, 200, 0) 0%, rgb(255, 100, 0) 100%);
  }

  &:disabled {
    background-color: #c1c1c1 !important;
    animation: none;
    cursor: auto;
  }
`;