import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
`

export const Box = styled.div`
  position: relative;
  padding: 20px;
  background-color: #21283f;
  flex-direction: column;
  border-radius: 6px;
  gap: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 1;

  > p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    > b {
      color: #09BD4D;
    }
  }

  > span {
    color: #09BD4D;
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 13px;

    > svg {
      margin-right: 12px;
    }
  }

  input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    color: #0F1835;
    outline: none;
    border: 1px solid #0F1835;
    background-color: white;
  }
`

export const BoxPix = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`

export const BoxContentPix = styled.div`
  border-radius: 8px;
  height: 90px;
  align-items: center;
  gap: 5px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #0F1835;
  margin: 20px 0px;
  color: #fff;
  background-color: ${props => props.active ? '#0F1835' : 'unset'};  
`



export const ContentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  padding: 32px 16px;
  background-color: #192137;
  border-radius: 8px;
  border: 1px solid grey;
  
  >  p{
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin: 0;

    > b {
    color: #53c776;
    font-weight: bold;
  }
  }

  
`