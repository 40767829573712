import React from 'react';
import PropTypes from 'prop-types';
import ImgLogo from '../../imgs/logo.png'
import { usePriceConsumer } from '../../store/price';
import { useSpring, animated } from 'react-spring';
import ImgIcon from '../../imgs/xx.png'


import * as Styled from './styles'
function Header({
  background,
  color,
  justifyContent,
  alignItems,
  margin,
  padding,
  fontWeight,
  isMobile,
  notFixed
}) {
  const hasPrice = localStorage.getItem('price');
const { price } = usePriceConsumer();

const { price: animatedNumber } = useSpring({
  from: { price: 50 },
  to: { price: hasPrice !== null ? JSON.parse(hasPrice) : price }, // Usando o valor do localStorage ou do contexto
  config: { duration: 1000 },
});

  return (
    <Styled.Content
      background={background}
      color={color}
      justifyContent={justifyContent}
      alignItems={alignItems}
      margin={margin}
      padding={padding}
      fontWeight={fontWeight}
      notFixed={notFixed}
    >
      <div className="container">
        <Styled.ContentGrid>
          <img src={ImgLogo} alt="" className='logo' />

          <Styled.BoxPrice>
            <img src={ImgIcon} alt="" className="icon" />
            
            <p>
              <span>R$ </span> <animated.div>{animatedNumber.interpolate((val) => Math.floor(val))}</animated.div>,00
            </p>
          </Styled.BoxPrice>
        </Styled.ContentGrid>
      </div>
    </Styled.Content>
  );
}

Header.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  fontWeight: PropTypes.string,
  notFixed: PropTypes.bool,
};

Header.defaultProps = {
  background: '#0F1835',
  color: '#fff',
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: '0px',
  padding: '20px 0',
  fontWeight: 'normal',
  notFixed: false
};

export default Header;
