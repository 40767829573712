import * as React from 'react';
import Modal from '@mui/material/Modal';
import * as Styled from './styles'
import Button from '../../components/base/Button'

export default function BasicModal() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  
  const isMobile = window.screen.width < 1000;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Styled.Content>
            <h1>PARABÉNS!</h1>
            <p>Você acaba de ganhar 1 licença gratuita pra avaliar marcas em nosso app!</p>
            <span>Aproveite, você ganhou R$ 50,00</span>
            <p>Faça 3 avaliações e realize seu primeiro saque!</p>

            <Button
                align="center"
                margin="0"
                text="Continuar"
                size={isMobile ? '13px' : '15px'}
                width={isMobile ? '80%' : 'auto'}
                // evtCategory="btn presell"
                background="#55c455"
                backgroundHover="#489348"
                // animationNone
                onClick={handleClose}
            />
            
        </Styled.Content>
      </Modal>
    </div>
  );
}