import React from 'react';
import Image from '../../imgs/like.svg'
import Text from '../../components/base/Text';
import Button from '../../components/base/Button'
import { useNavigate } from 'react-router-dom';


import * as Styled from './styles'

import ReactGA from "react-ga4";

function PageHome() {
  let navigate = useNavigate();



  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pagina presell", title: "pagina presell" });
    window.scrollTo(0, 0)
  }, [])
  
  const isMobile = window.screen.width < 1000;

  React.useEffect(() => {
    if (!isMobile) {
      ReactGA.send({ hitType: "pageview", page: "/home", title: "deslogando desktop" });
      window.location.href="about:blank";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    ReactGA.event({
      category: "Pagina presell button",
      action: "ButtonClick Presell",
    });
    navigate('/');

  }

  return (
    <Styled.Content>
      <div className="container">
        <img src={Image} alt="" style={{ margin: '0 auto', display: 'block' }} />
        <div style={{ margin: '65px 0' }}>
          <Text 
            text="Tudo certo!"
            align="center"
            size={isMobile ? '18px' : '21px'}
            fontWeight="bold"
            color="#fff"
          />

          <Text
            margin="10px 0 0"
            text="Clique em continuar para ser direcionado para a página solicitada."
            align="center"
            size={isMobile ? '18px' : '18px'}
            fontWeight="100"
            color="#fff"
          />
        </div>
        <Button
          align="center"
          margin="0"
          text="Continuar"
          size={isMobile ? '13px' : '15px'}
          width={isMobile ? '80%' : 'auto'}
          evtCategory="btn presell"
          background="#55c455"
          backgroundHover="#489348"
          animationNone
          onClick={handleClick}
        />
      </div>
    </Styled.Content>
  );
}

export default PageHome;
