import { createContext, useState, useContext } from "react";
import PropTypes from 'prop-types';

export const PriceContext = createContext()

export const PriceProvider = ({ children }) => {
  const [price, setPrice] = useState(50)

  return (
    <PriceContext.Provider
      value={{
        price,
        setPrice
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}

PriceProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const usePriceConsumer = () => useContext(PriceContext)