import React, { useState, useRef } from 'react'
import Text from '../../components/base/Text'
import { usePriceConsumer } from '../../store/price';
import { FaTired, FaFrown, FaMeh, FaSmile, FaLaughBeam } from "react-icons/fa";
import { QUESTIONS, QUESTIONS2 } from '../../constant'
import Audio from '../../imgs/cash.mp3'

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import ModalInfo from '../ModalInfo';
import * as Styled from './styles'

import 'swiper/css';
import 'swiper/css/effect-cards';

const ContentQuestionsButtonScale = ({ onClick, isDisabled }) => {
  const [activeBtn, setActiveBtn] = useState(null);

  const handleButtonClick = (btnId) => {
    if (!isDisabled) {
      setActiveBtn(btnId);
      onClick();
    }
  };

  const buttons = [
    { id: 1, color: "rgb(185, 28, 28)", icon: <FaTired style={{ color: '#fff', fontSize: '20px' }} /> },
    { id: 2, color: "rgb(194, 65, 12)", icon: <FaFrown style={{ color: '#fff', fontSize: '20px' }} /> },
    { id: 3, color: "rgb(202, 138, 4)", icon: <FaMeh style={{ color: '#fff', fontSize: '20px' }} /> },
    { id: 4, color: "rgb(101, 163, 13)", icon: <FaSmile style={{ color: '#fff', fontSize: '20px' }} /> },
    { id: 5, color: "rgb(13, 148, 136)", icon: <FaLaughBeam style={{ color: '#fff', fontSize: '20px' }} /> },
  ];

  return (
    <>
      {buttons.map((button) => (
        <Styled.BtnQuestionScale
          key={button.id}
          bgColor={button.color}
          active={activeBtn === button.id}
          onClick={() => handleButtonClick(button.id)}
          disabled={isDisabled}
        >
          {button.icon}
        </Styled.BtnQuestionScale>
      ))}
    </>
  );
};

const AppContent = ({ onSubmit, isNextStep }) => {
  const { setPrice, price } = usePriceConsumer();
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const QUESTIONS_FINAL = isNextStep ? QUESTIONS2 : QUESTIONS;
  const isLastItemActive = activeIndex === QUESTIONS_FINAL.length - 1;
  
  const toogleModal = () => {
    setShowModal(prevState => !prevState);
  };


  const goToNextQuestion = () => {
    setPrice(price + 43);
    localStorage.setItem('price', price + 43);
    if (isLastItemActive) {
      onSubmit();
    } else {
      setIsButtonsDisabled(true);
      setTimeout(() => {
        setIsButtonsDisabled(false);
      }, 3500);
      const audio = document.getElementById('audio');
      if (audio) {
        audio.play().catch(error => console.error("Erro ao tocar o áudio:", error));
      }
      

      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext();
      }
      setTimeout(() => {
        toogleModal(); // Abre o modal
  
        // Fecha o modal 2000ms após ser aberto
        setTimeout(() => {
          toogleModal(); // Fecha o modal
        }, 3000);
      }, 600);
      setIsButtonsDisabled(true);    
    }   
  };

  

  return (
    <Styled.Content>
      {showModal && <ModalInfo show={showModal} onClose={toogleModal} />}
      

      <audio id="audio" src={Audio} style={{ display: 'none' }} />
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        ref={swiperRef}
        allowTouchMove={false}
        simulateTouch={false} 
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {QUESTIONS_FINAL?.map((question, index) => (
          <SwiperSlide>
            <Styled.Box key={question.name}>
              <div>
              <Styled.Header>
                <img src={question?.imgBig} alt={question?.name} className='img-full' />
              </Styled.Header>

              <Styled.ContentQuestions>
                <div>
                  <Text
                    text={question?.questions}
                    color="#fff"
                    fontWeight="bold"
                    size="13px"
                    margin="0 0 12px"
                  />
                  <Styled.BoxScale>
                    <ContentQuestionsButtonScale onClick={goToNextQuestion} isDisabled={isButtonsDisabled} />
                  </Styled.BoxScale>
                </div>
              </Styled.ContentQuestions>
              </div>
            </Styled.Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Styled.Content>
  );
};

export default AppContent;