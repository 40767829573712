import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from "react-ga4";

import { BoxButton, Button, ButtonHelp } from './styles';

const ButtonBase = ({
  text,
  type,
  background,
  backgroundHover,
  color,
  margin,
  padding,
  width,
  size,
  onClick,
  align,
  showBtn,
  disabled,
  isHelp,
  evtCategory,
  evtAction,
  animationNone,
  loading
}) => {
  const handleClick = () => {
    if (!onClick) {
      ReactGA.event({
        category: evtCategory || "Home",
        action: evtAction || "ButtonClick",
      });
    }
    onClick()
    ReactGA.event({
      category: "Page video",
      action: "Clicou no botao de compra",
    });
  }

  return (
    <BoxButton align={align} showBtn={showBtn}>
      {isHelp ? (
        <ButtonHelp
        type={type}
        background={background}
        backgroundHover={backgroundHover}
        color={color}
        margin={margin}
        padding={padding}
        width={width}
        size={size}
        onClick={handleClick}
        disabled={disabled}
        align={align}
      >
        {text}
      </ButtonHelp>
      ) : (
        <Button
          type={type}
          background={loading ? '#c1c1c1' : background}
          backgroundHover={backgroundHover}
          color={color}
          margin={margin}
          padding={padding}
          width={width}
          size={size}
          onClick={handleClick}
          disabled={disabled || loading}
          animationNone={animationNone}
          align={align}
        >
          {loading ? 'Enviado...' : text}
        </Button>
      )}
    </BoxButton>
  )
};

ButtonBase.propTypes = {
  align: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  background: PropTypes.string,
  backgroundHover: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  size: PropTypes.string,
  showBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  isHelp: PropTypes.bool,
  animationNone: PropTypes.bool,
};

ButtonBase.defaultProps = {
  align: 'left',
  text: '',
  onClick: () => {},
  type: 'button',
  background: 'rgb(124, 58, 237)',
  backgroundHover: 'rgb(228 78 78)',
  color: '#fff',
  margin: 0,
  padding: '18.5px 45px',
  width: 'auto',
  size: '15px',
  showBtn: true,
  disabled: false,
  isHelp: false,
  animationNone: false
};

export default ButtonBase;
