import styled from 'styled-components'

export const Content = styled.div`
  margin-top: 35px;
  padding: 0 20px;
  overflow: hidden;
`


export const Box = styled.div`
  position: relative;
  padding: 20px;
  background-color: rgb(33, 40, 63);
  flex-direction: column;
  border-radius: 6px;
  gap: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 1;
  width: 320px;
`


export const Header = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 14px;
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .img-full {
    width: 100%;
    /* margin: 20px 0; */
  }
`

export const ContentQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const BoxBtns = styled.div`
  display: flex;
  gap: 16px;
`

export const BtnQuestion = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(226, 232, 240);
  width: 100%;
  padding: 0.6rem 0;
  font-weight: bold;
  border-radius: 0.25rem;
  transition: all 150ms ease;
  border: 0;
  color: ${props => props.active ? 'white' : 'rgb(51, 65, 85)'};
  transform: ${props => props.active ? 'scale(1.05)' : 'scale(1)'};
  background-image: ${props => props.active ? 'linear-gradient(to right, rgb(109, 40, 217), rgb(124, 58, 237))' : 'unset'};
`

export const BoxScale = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
`

export const BtnQuestionScale = styled.div`
  display: flex;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  transition-property: transform;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  transition: all 150ms ease;
  background-color: ${props => props.disabled ? '#ccc' : props.bgColor};
  transform: ${props => props.active ? 'scale(1.10)' : 'scale(1)'};
  filter: ${props => props.active ? 'brightness(125%)' : 'unset'};
`


export const BtnSubmit = styled.button`
  border: 0;
  width: 100%;
  border: 0;
  background-image: linear-gradient(to right, rgb(109, 40, 217), rgb(124, 58, 237));
  padding: 0.7rem 0;
  border-radius: 0.4rem;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  box-shadow: 0 10px 15px -3px rgba(124, 58, 237, 0.4);
`