import React from 'react';

function NotFound() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <h1>NotFound</h1>
    </>
  );
}

export default NotFound;