import styled from 'styled-components';

export const Text = styled.p`
  font-size: ${props => props.size};
  color: ${props => props.color};
  margin: ${props => props.margin};
  width: 100%;
  text-align: ${props => props.align};
  font-weight: ${props => props.fontWeight};
  text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};

  > span {
    color: #7c3aed;
    font-weight: bold;
  }

  > a {
    color: ${props => props.subColor};
    font-style: bold;
  }

  > em {
    font-size: 30px;
    font-style: normal;
  }

  > b {
    color: #000;
    font-weight: bold;
  }
`;


export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
