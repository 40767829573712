import styled from 'styled-components'



export const Content = styled.div`
  display: flex;
  background-color: ${props => props.background};
  color: ${props => props.color};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  font-weight: ${props => props.fontWeight};
  /* height: 64px; */
  /* position: ${props => props.notFixed ? 'unset' : 'fixed'}; */
  position: 'unset';
  width: 100%;
  z-index: 2;
  top: 0;
  


  .logo {
    width: 144px;
  }

  .icon {
    width: 30px;
  }
`

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 144px 40%;
  gap: 20px;
  justify-content: space-between;
  border-radius: 35px;
  padding: 10px;
  align-items: center;
  background-color: #21283f;
`

export const BoxPrice = styled.div`
  border-radius: 50px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(105, 105, 105);
  padding: 10px;
  justify-content: space-between;

  > p{
    text-align: center;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0;

    > span {
      color: #58BC7C;
      margin-right: 6px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  /* animation: pulse 1.5s ease-in-out infinite; */
`


export const ContentPriceNumber = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 16px;
  > span {
    margin-right: 2px;
  }
`


