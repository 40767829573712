import ImgNike from '../imgs/nike.png'
import ImgHavaianas from '../imgs/havaianas.png'
import ImgRenner from '../imgs/renner.jpg'

import ImgNatura from '../imgs/natura.jpg'
import ImgCoca from '../imgs/coca.jpg'
import ImgApple from '../imgs/iphone.webp'

export const SITE_NAME = 'HomePay';

export const QUESTIONS = [
  {
    name: 'Nike',
    imgBig: ImgNike,
    questions: 'Qual nota você da para os tenis da Nike?'
  },
  {
    name: 'Havaianas',
    imgBig: ImgHavaianas,
    questions: 'Qual nota você da para os chinelos havaianas?'
  },
  {
    name: 'Renner',
    imgBig: ImgRenner,
    questions: 'Qual nota você da para as roupas da Renner?'
  },
  {
    name: 'Natura',
    imgBig: ImgNatura,
    questions: 'Qual nota você da para os perfumes da natura?'
  },
  {
    name: 'Coca',
    imgBig: ImgCoca,
    questions: 'Qual nota você da para a coca-cola zero??'
  },
]

export const QUESTIONS2 = [
  {
    name: 'Natura',
    imgBig: ImgNatura,
    questions: 'Qual nota você da para os perfumes da natura?'
  },
  {
    name: 'Coca',
    imgBig: ImgCoca,
    questions: 'Qual nota você da para a coca-cola zero??'
  },
  {
    name: 'Apple',
    imgBig: ImgApple,
    questions: 'Qual nota você da para o Iphone 15?'
  },
]

