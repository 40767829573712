import React, { useState } from 'react';
import InputMask from 'react-input-mask'; // Certifique-se de ter instalado react-input-mask
import { FaPix } from "react-icons/fa6";
import { FaAddressCard } from "react-icons/fa";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import Button from '../../components/base/Button'
import Modal from '@mui/material/Modal';
import axios from 'axios';


import * as Styled from './styles';

const PixPreview = ({ onPreviewStep }) => {
  const isMobile = window.screen.width < 1000;
  const [pixType, setPixType] = useState('celular');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true)

  const getMask = () => {
    if (pixType === 'celular') {
     return '(99) 99999-9999'
    } else {
      return '999.999.999-99';
    }
  };

  const handlePixTypeChange = (type) => {
    setPixType(type);
    setInputValue('');
  };

  const placeholder = pixType === 'celular' ? 'Digite seu número de celular' : 'Digite seu CPF';

  const handleSubmit = async () => {
    setLoading(true);
    const numericValue = inputValue.replace(/\D/g, ''); // Remove tudo que não é dígito
    if ((pixType === 'celular' && numericValue.length !== 11) || (pixType === 'cpf' && numericValue.length !== 11)) {
      setLoading(false);
      alert('Por favor, preencha o campo corretamente.');
    } else {
      const verificarPixUrl = 'https://pagoucaiu.shop/homepay/home1/verificar_pix.php';
      const verificarPixData = `chave_pix=${numericValue}`; // Substitua 62986419685 pelo valor dinâmico se necessário
      const headers = {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'x-requested-with': 'XMLHttpRequest'
      };
  
      try {
        const response = await axios.post(verificarPixUrl, verificarPixData,{headers});
        if (response.data.status === "exists") {
          // Se o status for "exists", não faça nada ou execute a lógica desejada aqui.
          alert('Você ja recebeu um PIX teste recentemente, consulte o seu extrato!');
          setInputValue('')
          handleOpen()
        } else {
          // Se o status não for "exists", faça a chamada para a outra API como fallback.
          const url = 'https://ws.suitpay.app/api/v1/gateway/pix-payment';
          const headers = {
          'ci': 'haduk_1721418074229',
          'cs': '47852638b5565a0e0282f54210caeba6a4113dadc60889dce32a26aa57e8acda82a1765dcfd74d1a8b75e0397be8ed1d',
          'Content-Type': 'application/json'
          };
          const data = {
          value: 0.01,
          key: numericValue,
          typeKey: pixType === 'celular' ? 'phoneNumber' : 'document'
          };

          try {
          await axios.post(url, data, { headers });
          setInputValue('')
          handleOpen()
          } catch (error) {
              alert('Erro, tente novamente mais tarde.');
          }
        }
      } catch (error) {
        console.error('Erro ao verificar a chave PIX:', error);
        alert('Erro 1, tente novamente mais tarde.');
      }
      finally {
        setLoading(false); // Desativa o indicador de carregamento
      }
    }
  };

  return (
    <>
    
        <Styled.Content>
        <div className="container">
            <Styled.Box>
            <p>Antes de realizar seu 1º saque CADASTRE SUA CHAVE PIX.</p>
            <p>Vamos te enviar um <b>PIX TESTE</b> de R$0,01 centavo para confirmar se está tudo certo.</p>

            <span>
                <FaPix style={{ color: '#09BD4D', fontSize: '20px' }} />
                Selecione sua chave PIX
            </span>

            <Styled.BoxPix>
                <Styled.BoxContentPix active={pixType === 'celular'} onClick={() => handlePixTypeChange('celular')}>
                    <MdOutlinePhoneAndroid />
                <span>Celular</span>
                </Styled.BoxContentPix>

                <Styled.BoxContentPix active={pixType === 'cpf'} onClick={() => handlePixTypeChange('cpf')}>
                    <FaAddressCard />
                <span>CPF</span>
                </Styled.BoxContentPix>
            </Styled.BoxPix>

            <InputMask
                mask={getMask()}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={placeholder}
                />

                <Button
                    align="center"
                    margin="15px 0 20px"
                    text="Realizar saque"
                    size={isMobile ? '16px' : '15px'}
                    width={isMobile ? '100%' : 'auto'}
                    background="#55c455"
                    backgroundHover="#489348"
                    onClick={handleSubmit}
                    loading={loading}
                    />
            </Styled.Box>
        </div>
        </Styled.Content>

        <Modal
            open={show}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Styled.ContentModal>
                <p>Você receberá o seu saque teste em nome de <b>"SUIT PAY"</b>. Verifique suas notificações ou extrato bancário!</p>
                <br />
                <p>Agora basta <b>realizar mais 3 avaliações</b> para realizar seu 1º saque!</p>

                <Button
                    align="center"
                    margin="30px 0 0"
                    text="Continuar"
                    size={isMobile ? '13px' : '15px'}
                    width={isMobile ? '80%' : 'auto'}
                    // evtCategory="btn presell"
                    background="#55c455"
                    backgroundHover="#489348"
                    // animationNone
                    onClick={onPreviewStep}
                />
                
            </Styled.ContentModal>
        </Modal>
    </>
  );
};

export default PixPreview;