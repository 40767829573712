import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 92px;
  background: #1c1c1c;
  align-items: center;
  justify-content: center;
  z-index: 999;

  @media (min-width: 1010px) {
    display: none;
  }
`

export const Menu = styled.div`
  display: flex;

  bottom: 0;
  width: 100%;
  height: 110px;
  position: fixed;

  > img {
    width: auto;
    display: block;
    margin: 0 auto;
    max-width: 360px;
    height: 95px;
  }
`

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh; /* Altura padrão */
  height: calc(100vh - env(safe-area-inset-bottom)); /* Ajuste aqui */
`

export const BoxInfossss = styled.div`
  height: 80vh;
    padding-bottom: 40px;
    overflow: scroll;
`




