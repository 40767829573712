import styled from 'styled-components'

export const BtnPix = styled.button`
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #7c3aed;
  padding: 13px 21px;
  width: max-content;
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 1.4rem;
  gap: 5px;
  border-radius: 10px;
  cursor: pointer !important;
  font-size: 21px;
  font-weight: bold;
  border: 0;
  margin-top: 25px;
  margin-bottom: 14px;
`

export const Progressbar = styled.div`
  width: 100%;
  background-color: #0F1835;
  margin: 0 0px 20px;
  border-radius: 20px;

  > div {
    border-radius: 10px;
    width: 1%;
    height: 30px;
    background-color: #209743;
  }
`

export const BoxPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > p{
    text-align: center;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 27px;

    > span {
      color: #58BC7C;
      margin: 0 6px 0 0;
    }
  }
`

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  padding: 32px 16px;
  background-color: #192137;
  border-radius: 8px;
  border: 1px solid grey;
  
  > h1, p, span {
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin: 0;
  }

  h1 {
    margin: 17px 0;
  }

  span {
    color: #53c776;
    margin: 17px 0;
  }
`